<template>
  <div class="tfw-candidate-register mt-2">
    <div v-if="[0, 1, 2].includes(step)" class="candidate-register-1">
      <CRow class="mx-0">
        <div class="content col-md-7 pb-3">
          <h2 class="text-white heading-1">
            {{ step !== 2 ? "Registration" : "" }}
          </h2>
          <div v-if="step === 0">
            <h3 class="text-white heading-3">Please create your user email ID</h3>
            <div class="px-0">
              <CRow class="mb-3">
                <CCol md="8">
                  <ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
                    <TextInput
                      name="email"
                      placeholder="Enter your email"
                      :value="existingCandidate.email"
                      @input="handleExistingCandidateInput"
                      autocomplete="login-email"
                      ref="existingCandidate"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow class="mb-3">
                <CCol md="8">
                  <ValidationProvider rules="required|email|emailConfirmed:email" v-slot="{ errors }">
                    <TextInput
                      name="confirm_email"
                      placeholder="Confirm your email"
                      :value="existingCandidate.confirm_email"
                      @input="handleExistingCandidateInput"
                      autocomplete="login-email"
                      ref="existingCandidate"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="4" class="mb-3">
                  <button
                    name="back-button"
                    style="width: 100%"
                    class="btn btn-secondary btn-tfw"
                    @click="$router.push('/tfw')"
                  >
                    Back
                  </button>
                </CCol>
                <CCol md="4" class="mb-3">
                  <button
                    name="registration-submit"
                    style="width: 100%"
                    class="btn btn-primary btn-tfw"
                    type="submit"
                    @click="checkCandidateExists"
                    :disabled="isLoading"
                  >
                    <CSpinner v-if="isLoading" class="spinner-border-sm text-white ml-1" />
                    <span v-else>Next</span>
                  </button>
                </CCol>
              </CRow>
            </div>
          </div>
          <div v-else-if="step === 1" class="text-white">
            <p class="h1">
              Welcome Back
              <span class="font-weight-bold">{{
                getRegisteredMasterCandidateName | toTitleCase
              }}</span>
            </p>
            <div style="margin-left: -15px;" >
              <CCol class="col-10 mb-2">
                <h4>Email ID / Username</h4>
              </CCol>
              <CCol class="col-10 mb-3 mt-3">
                <TextInput
                  :value="existingCandidate.email"
                  :disabled="true"
                />
              </CCol>
            </div>
            <h5 class="mb-4" style="line-height: 1.5;">
              Your details are already registered with
              <b>{{ getRegisteredMasterCustomerName }}</b
              >, a member of the TalentFind Marketplace network.
              <br/>
              <a
                href="#"
                class="font-weight-bold"
                @click="getMasterCustomer(getRegisteredMasterCustomerID, 2)"
                >click here</a
              >
              to use your <b>{{ getRegisteredMasterCustomerName }}</b>
              profile to quickly register with TalentFind Marketplace.
            </h5>
            <!-- <RadioButton
              name="customer_id"
              :value="userData.customer_id"
              @change="handleChangeRadio"
              class="radio-field"
              :vertically="true"
              :options="registeredOrgOpt || []"
            /> -->
            <CRow class="mt-3">
              <CCol md="8" class="d-flex justify-content-between">
                <CButton
                  variant="outline"
                  color="primary"
                  name="step1-home"
                  class="btn-tfw"
                  to="/tfw"
                >
                  <span>Home</span>
                </CButton>
                <!-- <CButton
                  name="step1-next"
                  color="primary"
                  class="btn-tfw"
                  :disabled="isLoading"
                  @click="checkField('customer_id', 2)"
                >
                  <span>Next</span>
                  <CSpinner
                    v-if="isLoading"
                    class="spinner-border-sm text-white m-1"
                  /> 
                </CButton> -->
              </CCol>
            </CRow>
          </div>
          <div v-else-if="step === 2" class="text-white">
            <h1 class="mb-3">Welcome To TalentFind Marketplace</h1>
            <h3 class="font-weight-bold mb-2">
              Hello
              {{
                (isAlreadyRegistered
                  ? userInfo.candidate_name
                  : getRegisteredMasterCandidateName) | toTitleCase
              }}!
            </h3>
            <CRow class="mb-3">
              <CCol md="12">
                <label class="required text-white"
                  >Please enter your Password</label
                >
              </CCol>
              <CCol md="8">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextInput
                    name="password"
                    type="password"
                    :value="userData.password"
                    @input="handleInput"
                    autocomplete="login-passoword"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-left">
                <CButton
                  @click="redirectToForgot"
                  color="link"
                  class="px-0"
                  style="color: #fff"
                  >Forgot password?</CButton
                >
              </CCol>
            </CRow>
            <CRow class="mt-3">
              <CCol md="8" class="d-flex justify-content-between">
                <CButton
                  variant="outline"
                  color="primary"
                  name="step2-back"
                  class="btn-tfw"
                  @click="goBack()"
                >
                  <span>Back</span>
                </CButton>
                <CButton
                  v-if="isAlreadyRegistered"
                  name="step2-register"
                  color="primary"
                  class="btn-tfw"
                  :disabled="isLoading"
                  @click="onLoginCandidate()"
                >
                  <span>Login</span>
                  <CSpinner
                    v-if="isLoading"
                    class="spinner-border-sm text-white m-1"
                  />
                </CButton>
                <CButton
                  v-else
                  name="step2-register"
                  color="primary"
                  class="btn-tfw"
                  :disabled="isLoading"
                  @click="onExistingCandidate()"
                >
                  <span>Register</span>
                  <CSpinner
                    v-if="isLoading"
                    class="spinner-border-sm text-white m-1"
                  />
                </CButton>
              </CCol>
            </CRow>
          </div>
        </div>
      </CRow>
      <img
        src="/img/tfw/hero-image.png"
        sizes="(max-width: 479px) 80vw, (max-width: 991px) 70vw, 100vw"
        style=""
        alt=""
        class="landing-image"
      />
    </div>
    <div
      class="registration container-md col-lg-8 col-md-8"
      v-else-if="step === 3"
    >
      <h3 
        v-if="!getCandidateCreated" 
        class="my-4 text-white font-weight-bold"
      >
        Registration
      </h3>
      <QuickRegistration
        v-if="!getCandidateCreated"
        :customer="getCustomerID"
        :organisationID="getOrgID"
        :locationID="getLocationID"
        :profilePhoto="profilePhoto"
        :isSubmitted="isSubmitted"
        :existingCandidate="existingCandidate"
        :campaignData="candidate"
      />

      <div
        v-else-if="getCandidateCreated"
        class="jumbotron bg-white text-center m-auto"
      >
        <h1 class="text-capitalize" name="user_first_name">
          Welcome {{ candidateProfile.first_name }}!
        </h1>
        <div class="login">
          <div class="message">
            <p>Registration Successful!</p>
            <p>
              Please ensure your CV has been uploaded successfully. All
              additional information and supporting documents will assist in
              identifying suitable positions, which will be highlighted to you.
            </p>
            <p>
              Manage your applications here, so please continue to log in and
              check for messages and updates.
            </p>
            <p>
              We have emailed a confirmation of your registration – but please
              check your Junk mail.
            </p>
            <p
              class="mb-4"
              :name="`registered_cand-id-${candidateProfile.candidate_uid}`"
            >
              THANKS FOR REGISTERING!
            </p>
          </div>
          <div style="padding-bottom: 150px !important">
            <button
              v-if="getOrgVariant == Variant.campaign"
              @click="redirectToUserRegister()"
              class="btn rounded-0 px-5 btn-primary btn-lg"
            >
              Sign up Again
            </button>
            <button
              v-else
              @click="redirectToLogin()"
              class="btn rounded-0 px-5 btn-primary btn-lg"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <Modal
      :modalTitle="emailConfirmModal.modalTitle"
      :modalColor="emailConfirmModal.modalColor"
      :modalContent="emailConfirmModal.modalContent"
      :isShowPopup="emailConfirmModal.isShowPopup"
      :buttons="emailConfirmModal.buttons"
      :showCloseButton="emailConfirmModal.showCloseButton"
      :modalCallBack="emailConfirmModalCallBack"
      :closeOnBackdrop="false"
    /> -->
  </div>
</template>
<script>
import {
  LOGIN_URL,
  Variant,
  getLocalOrgDetail,
  isObject,
  getFilterQueryStringWithoutArray,
} from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import Registration from "@/containers/CandidateProfile/Registration";
import TextInput from "@/components/reusable/Fields/TextInput";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { extend } from "vee-validate";
import { required, email, regex, confirmed } from "vee-validate/dist/rules";
import Vue from "vue";
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email" });
extend("emailConfirmed", { ...confirmed, message: "The email addresses entered do not match. Please correct and try again." });

import QuickRegistration from "@/containers/CandidateProfile/Registration/QuickRegistration";
import Modal from "@/components/reusable/Modal";
import _ from "lodash";

export default {
  name: "TFWRegister",
  components: {
    Registration,
    TextInput,
    QuickRegistration,
    RadioButton,
    Modal,
  },
  data() {
    return {
      Variant,
      existingCandidate: {},
      isLoading: false,
      step: 0,
      profilePhoto: {},
      isSubmitted: false,
      isAlreadyRegistered: false,
      userData: {},
      registeredOrg: [],
      isRegistered: false,
      emailConfirmModal: {
        modalColor: "modal-primary",
        modalTitle: "NOTIFICATION",
        modalContent: "Please verify that the provided email ID is correct.",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        showCloseButton: false,
      },
      isEmailDisabled: this.campaignFieldsData ? true : false,
      userInfo: {},
      candidate: {},
    };
  },
  computed: {
    ...mapGetters([
      "getOrgDetail",
      "getOrgVariant",
      "candidateProfile",
      "isAcronymBasedLogin",
      "registerCandidateData",
      "getRegistrationStep",
      "getCandidateCreated",
    ]),
    getCustomerID() {
      return getLocalOrgDetail()?.customer_uid || null;
    },
    getOrgID() {
      return getLocalOrgDetail()?.organisation_id || null;
    },
    getLocationID() {
      return getLocalOrgDetail()?.location_id || null;
    },
    getOrgName() {
      return this.getOrgDetail?.name || null;
    },
    selectedCustomerOrg() {
      return this.userData?.customer_id?.label || null;
    },
    registeredOrgOpt() {
      const static_opt = {
        code: 0,
        label: "I want to create a new profile in TalentFind Marketplace",
      };
      return [
        ...(this.registeredOrg?.map((v) => ({
          code: v?.customer_uid,
          label: `${v?.candidate_name} - ${v?.candidate_type} - ${v?.customer_name}`,
          rawData: v,
        })) || []),
        static_opt,
      ];
    },
    registeredMasterOrg() {
      return this.registeredOrg?.filter((v) => v?.is_master) || [];
    },
    getRegisteredMasterCustomerName() {
      return this.registeredMasterOrg[0].customer_name || "";
    },
    getRegisteredMasterCandidateName() {
      return this.registeredMasterOrg[0].candidate_name || "";
    },
    getRegisteredMasterCustomerID() {
      return this.registeredMasterOrg[0].customer_uid || "";
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "getRegisteredHospitalsByEmail",
      "registerCommonCandidateForTFW",
      "login",
      "checkCandidateExistInCampaign",
      "setCandidateCreated",
    ]),
    checkField(field, step) {
      if (this.userData[field] != undefined && this.userData) {
        if (field === "customer_id" && this.userData[field]?.code === 0) {
          this.existingCandidate.email = "";
          this.step = 0;
          return;
        }
        this.step = step;
        return;
      }
      this.showToast({
        class: "bg-danger text-white",
        message: "Please choose your base customer!",
      });
    },
    handleInput(name, value) {
      Vue.set(this.userData, name, value);
    },
    handleExistingCandidateInput(name, value) {
      if (name === "email") value = value?.toLowerCase().trim();
      if (name === "confirm_email") value = value?.toLowerCase().trim();
      Vue.set(this.existingCandidate, name, value);
      this.isAlreadyRegistered = false;
    },
    handleChangeRadio(name, value) {
      Vue.set(this.userData, name, value);
    },
    onExistingCandidate() {
      // this.$refs.existingCandidatePassword.validate();
      const isPasswordEntered = this.userData?.password;
      if (isPasswordEntered) {
        let payload = {
          email: this.existingCandidate?.email,
          customer_uid: this.getCustomerID,
          organisation_id: this.getOrgID ? this.getOrgID : null,
          location_id: this.getLocationID ? this.getLocationID : null,
          password: isPasswordEntered,
        };
        this.registerCommonCandidateForTFW(payload).then((res) => {
          if(res?.status == 200) {
             this.login({
          username: this.existingCandidate?.email,
          password: isPasswordEntered,
        });
          }
        })
      }
    },
    onLoginCandidate() {
      const password = this.userData?.password;
      if(!password){
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter password!",
        });
        return false;
      }
      const data = {
        username: this.existingCandidate?.email,
        password: this.userData?.password,
      };
      this.login(data);
    },
    checkCandidateExists() {
      this.isLoading = true;
      if (!this.existingCandidate["email"]) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.existingCandidate["email"]).toLowerCase())) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Enter valid email!",
        });
        this.isLoading = false;
        return false;
      }
      if (
        this.existingCandidate["email"] ===
        this.existingCandidate["confirm_email"]
      ) {
        const payload = {
          email: this.existingCandidate["email"],
          customer_uid: this.getCustomerID,
          organisation_uid: this.getOrgID,
          check_registered: true,
        };
        this.isAlreadyRegistered = false;
        this.registeredOrg = [];
        this.getRegisteredHospitalsByEmail(payload).then((res) => {
          this.isLoading = false;
          const errMsg = res?.response?.data?.detail?.message || res?.response?.data?.detail;
          if (res?.response?.status === 409) {
            this.showToast({
              class: "bg-danger text-white",
              errMsg,
            });
          } else if (
            res?.response?.status === 400 &&
            errMsg?.includes("Already registered")
          ) {
            // this.$router.push({ path: `/tfw/login` });
            this.isAlreadyRegistered = true;
            this.userInfo = {
              candidate_name: res?.response?.data?.detail?.candidate_name,
            };
            this.step = 2;
          } else {
            if (res?.status === 200) {
              const { data } = res;
              if (data && data.length && isObject(data[0])) {
                this.userData["username"] = this.existingCandidate["email"];
                this.registeredOrg = data;
                this.step = 1;
                return;
              }
              const payload = {
                email: this.existingCandidate["email"],
              }
              this.checkCandidateExistInCampaign(payload).then((res) => {
                if(res) {
                  this.candidate = res
                }
                this.step = 3;
                return res;
              })
            }
          }
        });
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "The email addresses entered do not match. Please correct and try again.",
        });
        this.isLoading = false;
        return false;
      }
    },
    redirectToLogin() {
      this.$router.push({ path: `/web/login` });
    },
    getMasterCustomer(customer_id, step) {
      this.userData.customer_id = customer_id;
      this.checkField("customer_id", step);
    },
    redirectToForgot() {
      this.$router.push({
        path: `${
          this.isAcronymBasedLogin ? `${LOGIN_URL()}/forgot` : "/forgot"
        }`,
      });
    },
    goBack() {
      this.step = 0;
      this.existingCandidate = {};
    },
    // emailConfirmModalCallBack(action){
    //   if(this.existingCandidate.email && !this.$refs.existingCandidate.error){
    //     this.emailConfirmModal.isShowPopup = true;
    //     this.emailConfirmModal.modalContent = `Please verify that the provided email ID "${this.existingCandidate.email}" is correct.`;
    //     if(action === 'Confirm'){
    //       this.emailConfirmModal.isShowPopup = false;
    //       this.checkCandidateExists();
    //     }
    //     else if(action === 'Cancel'){
    //       if(this.fromCampaignPage){
    //         this.isEmailDisabled  = false;
    //       }
    //       this.emailConfirmModal.isShowPopup = false;
    //     }
    //   }
    //   else{
    //     this.checkCandidateExists();
    //   }
    // },
  },
  mounted() {
    this.setCandidateCreated(false);
    this.registeredOrg = this.registerCandidateData;
    if(this.registeredOrg && this.registeredOrg.length) {
      this.existingCandidate.email = this.registeredOrg[0]?.email
      this.step = this.getRegistrationStep
    }
  },
  beforeDestroy() {
    this.$store.commit('IS_CANDIDATE_CREATED', false);
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('CLEAR_CANDIDATE_DATA');
    next();
  },
  filters: {
    toTitleCase(data) {
      return data ? _.startCase(data) : data;
    }
  }
};
</script>
